.section-heading h1 {
    font-size: 3rem;
}

.section-para pre {
    font-size: 1.5rem;
}

@media screen and (max-width: 767px) {
    .section-heading h1 {
        font-size: 2rem;
    }
    .section-heading h3 {
        font-size: 1rem;
    }

    .section-para pre {
        font-size: 0.5rem;
    }
}