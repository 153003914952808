/* #contact-us {
  background: url("./../../assets/images/contact-usx2.png") no-repeat center
    center;
  height: 80%;
  border-radius: 10px;
  padding-top: 8rem;
  padding-bottom: 8rem;
  position: relative;
  width: 100%;
  background-size: cover;
} */
.letter-spacing {
  letter-spacing: 0.36px;
}
.button .btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
}
.button .btn-primary:hover {
  background-color: var(--primary);
  border-color: var(--primary);
}
.button .btn-outline-secondary {
  border-color: var(--white);
  color: var(--white);
}
.button .btn-outline-secondary:hover {
  background: none;
  border-color: var(--white);
}
/* @media (min-width: 992px){
  #contact-us{
    background-size: contain;
    padding-top: 1rem;
    background-position: top;
    padding-bottom: 1rem;
  }
  .landing-text .text-start{
    margin: 1.5rem;
  }
}
@media (min-width: 768px){
 #contact-us{
  background-size: contain;
  padding-top: 0rem;
  background-position: top;
  padding-bottom: 0rem;

 } 
}
@media (min-width: 1200px){
  #contact-us{
    background-size: contain;
    padding-top: 1rem;
  }
} */
@media (max-width: 550px) {
  #contact-us {
    background-size: contain;
    padding-top: 3rem;
  }
}
@media (max-width: 650px) {
  #contact-us {
    background-size: contain;
    margin-top: 1rem;
  }
}

/* Container holding the image and the text */
.bg-img-text {
  position: relative;
  text-align: center;
}

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 22%;
  transform: translate(-50%, -50%);
}
.about-us {
  position: absolute;
  top: 50%;
  left: 37%;
  transform: translate(-50%, -50%);
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .centered {
    left: 21%;
  }
}
@media (max-width: 1024px) {
  .centered {
    left: 40%;
  }
}
@media (max-width: 768px) {
  .centered {
    left: 40%;
  }
}
