.contact-form .form-control:focus {
  border-bottom: 1px solid var(--primary);
  box-shadow: none;
}
.contact-form .form-select:focus {
  border-bottom: 1px solid var(--primary);
  box-shadow: none;
}
.contact-form label:after {
    content:" *";
  }
