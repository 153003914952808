.navbar.navbar-light {
  background: #ffffff;
  /* box-shadow: 0 8px 32px 0 rgb(31 38 135 / 18%); */
  backdrop-filter: blur(38.5px);
  border: 1px solid rgb(167 165 165 / 18%);
}
.nav-item .nav-link {
  color: var(--black);
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}
.navbar-nav .nav-item .nav-link.active,
.navbar-nav .show > .nav-link {
  color: var(--secondary);
  border-bottom: 2px solid var(--secondary);
  font-weight: 600;
}
.contact-us button {
  background-color: var(--primary);
  border-color: var(--primary);
}
.contact-us button:hover {
  background-color: var(--primary);
  border-color: var(--primary);
}
/* .navbar-nav .nav-item .nav-link.active::before{
    content: "";
    border-bottom: 2px solid var(--secondary);
    width: 20px;
    height: 20px;
    position: absolute;
    margin: auto;
    padding-top: 22px;
} */
