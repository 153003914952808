.slick-dots li button:before,
.slick-dots li.slick-active button:before {
  color: var(--primary);
  font-size: 12px;
}

.slick-dots li {
  margin: 0;
}

.slick-slider {
  /* height: calc(100vh - 180px); */
  height: 75vh;
}

/* .div-1 {
  display: inherit !important;
  background: url("./../../assets/images/Slide_first_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding-left: 1rem;
  border-radius: 10px;
} */
/* @media (min-width: 1024px) and (max-width: 1300px) {
  .div-1 {
    background-size: cover;
  }
  .slick-slider {
    height: calc(100vh - 452px);
  }
} */
/* @media (max-width: 768px) {
  .div-1 {
    background-size: cover;
  }
  .slick-slider {
    height: calc(100vh - 531px);
  }
}
@media (max-width: 430px) {
  .slick-slider {
    height: calc(100vh - 685px);
  }
} */
/* @media (max-width: 400px) {
  .div-1 {
    background-size: contain;
  }
  .slick-slider {
    height: calc(100vh - 531px);
  }
} */
@media (max-width: 375px) {
  .div-1 {
    background-size: cover;
  }
}


.carousel-container {
  max-width: 95vw;
}

.mt-n20 {
  margin-top: -20px;

}
 @media screen and (max-width: 767px) {
  .background-blur{
    background: rgba(0, 0, 0, 0.46);
    color: #fff !important;
  }

 }

/* .content-box {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
} */
/* @media (max-width: 1024px) {
  .div-1,
  .div-2,
  .div-3 {
    background-size: contain;
  }
  .slick-slider {
    height: calc(100vh - 440px);
  }
}
@media only screen and (max-width: 912px){
  .slick-slider {
    height: calc(100vh - 958px);
  } 
}
@media only screen and (max-width: 820px){
  .slick-slider {
    height: calc(100vh - 815px);
  } 
}
@media (max-width: 1024px) and (max-height: 600px) {
  .div-1,
  .div-2,
  .div-3 {
    background-size: contain;
  }
  .slick-slider {
    height: calc(100vh - 160px);
  }
}

@media (max-width: 768px) {
  .div-1,
  .div-2,
  .div-3 {
    background-size: contain;
  }
  .slick-slider {
    height: calc(100vh - 528px);
  }
}
@media (max-width: 768px) and (min-height: 1024px){
  .slick-slider {
    height: calc(100vh - 675px);
  } 
}
@media only screen and (max-width: 540px){
  .slick-slider {
    height: calc(100vh - 485px);
  } 
}
@media (max-width: 400px) {
  .slick-slider {
    height: calc(100vh - 715px);
  }
  .div-1,
  .div-2,
  .div-3 {
    background-size: contain;
  }
}
@media (min-width: 400px) and (max-width: 430px) {
  .carousel-container {
    max-width: 85vw;
  }
  .slick-slider {
    height: calc(100vh - 692px);
  }
  .div-1,
  .div-2,
  .div-3 {
    background-size: contain;
  }
}
@media (max-width:360px) and (min-width:375px){
  .slick-slider {
    height: calc(100vh - 575px);
  } 
}
@media (max-width:375px) and (min-width:390px){
  .slick-slider {
    height: calc(100vh - 500px);
  }  
}
@media (max-width:390px) and (min-width:400px){
  .slick-slider {
    height: calc(100vh - 670px);
  }  
}
@media (max-width:300px){
  .slick-slider {
    height: calc(100vh - 515px);
  }
} */