.card {
  padding: 5px;
  background-color: #ffffff;
  cursor: pointer;
}

.card:hover .card-img-top {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.card .content {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.card .back {
  position: absolute;
  width: 100%;
  /* transition: 1s; */
  z-index: 1;
  border-radius: 10px;
  display: flex;
  padding: 20px 20px 0px 20px;
}
.from-bottom {
  top: 100%;
}
.card .content .img-hover:hover .from-bottom {
  top: 0%;
}
