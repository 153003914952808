html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  color: var(--eerie-black);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  --primary: #800080;
  --secondary: #d9614d;
  --light-purple: #8000800f;
  --light-orange: #faeeec;
  --off-white: #fefaf9;
  --eerie-black: #141414;
  --light-grey: #f9f9f9;
  --grey: #efefef;
  --white: #ffffff;
  --blue: #1650d3;
  --black: #000000;
}
.bg-off-white {
  background-color: var(--off-white);
}
.bg .bg-grey {
  background-color: var(--grey);
}
.bg .bg-light-grey {
  background-color: var(--light-grey);
}
.bg-light-orange {
  background-color: var(--light-orange);
}
.bg-color-primary {
  background-color: var(--primary);
}
.ff-playfair {
  font-family: "Playfair Display", serif;
}
.ff-montserrat {
  font-family: "Montserrat", sans-serif;
}
.fc-blue {
  color: var(--blue);
}
.text-color-secondary {
  color: var(--secondary);
}
.text-color-primary {
  color: var(--primary);
}
.button .btn-secondary {
  background-color: var(--secondary);
  border-color: var(--secondary);
}
.button .btn-secondary:hover {
  background-color: var(--secondary);
  border-color: var(--secondary);
}
.button .btn-secondary:active {
  background-color: var(--secondary) !important;
  border-color: var(--secondary) !important;
}
.ff-montserrat.fw-600 {
  font-weight: 600;
}
.bg-gradient-light-orange {
  background: linear-gradient(
    90deg,
    rgba(250, 238, 224, 1) 8%,
    rgba(250, 238, 236, 0) 100%
  );
}
.card .border-orange {
  border-left: 3px solid var(--secondary);
}
.h-93 {
  height: 93%;
}
@media (min-width: 992px) {
  .w-lg-auto {
    width: auto;
  }
}
@media (min-width: 1400px) {
  .w-xxl-50 {
    width: 50%;
  }
}
.button .btn-secondary:active {
  background-color: var(--secondary) !important;
  border-color: var(--secondary) !important;
}
.button .btn-secondary:active {
  background-color: var(--secondary) !important;
  border-color: var(--secondary) !important;
}
.button .btn:focus {
  outline: none;
  box-shadow: none;
}
.button .btn.btn-primary:focus {
  outline: none;
  box-shadow: none;
  color: var(--white);
  background-color: var(--primary);
  border-color: var(--primary);
  box-shadow: var(--primary);
}
.button .btn.btn-outline-secondary:focus {
  outline: none;
  box-shadow: none;
  color: var(--white);
  background-color: transparent;
  border-color: var(--white);
  box-shadow: var(--white);
}
.button .btn.btn-primary:first-child:active {
  background: var(--primary);
  color: var(--white);
  border-color: var(--primary);
}
.button .btn.btn-outline-secondary:first-child:active {
  background: transparent;
  color: inherit;
  border-color: var(--white);
}
.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}
.text-justify {
  text-align: justify;
}
.pb-10{
  padding-bottom: 5rem;
}
@media (min-width: 768px){
  .pb-10{
    padding-bottom: 8rem;
  }
}
@media (min-width: 992px){
  .pb-10{
    padding-bottom: 9rem;
  }
}
@media (min-width: 1400px) {
  .pb-10{
    padding-bottom: 10rem;
  }
}
.btn.btn-secondary:disabled{
  background-color: var(--secondary) !important;
  border-color: var(--secondary) !important; 
}
.card.card-hover:hover{
  box-shadow: 0 4px 25px 0 rgba(180, 179, 179, 0.3), 0 0 1px 0 rgba(0,0,0,.25);
}
